.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border: 1px solid #0000001f;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1344px;

    .container-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-left-side {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .cleverpine-logo {
        width: 160px;
        padding: 0;
      }
    }

    .header-right-side {
      display: flex;
      align-items: center;
    }
  }
}

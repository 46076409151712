.sign-in-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 520px;
  box-shadow: 0px 11px 19px #00000033;
  padding: 80px 40px;
}

.text {
  font-style: italic;
  font-size: 14px;
}

.sign-in-btn {
  margin-top: 3%;
  margin-bottom: 2%;
}

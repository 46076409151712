.pinecone {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  z-index: -1;
}

.app-version {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  color: #cdcdcd;
}

@font-face {
  font-family: 'Regular';
  src: local('AirbnbCerealLight'),
    url('./fonts/axiforma/Kastelov\ -\ Axiforma\ Regular.otf')
      format('truetype');
}

@font-face {
  font-family: 'Bold';
  src: local('AirbnbCerealLight'),
    url('./fonts/axiforma/Kastelov\ -\ Axiforma\ Bold.otf') format('truetype');
}

a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

ul {
  list-style-type: none;
}
